import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { getCookie } from './components/helpers.js';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
        currentUser: null,
        email: null,
        password: null,
        rememberMe: false,
        photoURL: null,
        message: '',
        messageClassName: '',
        loggedIn: false,
        validated: false,
        sidebarNav: []
    };
  }

  myChangeHandler = (event) => { 
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });

    if (nam == 'rememberMe') {
        this.setState({ rememberMe: event.target.checked });
    }
  }

  componentDidUpdate(prevProps, prevState)
  {
      if((this.state.photoURL == null) && this.state.currentUser != null)
      {
          var currentTime = new Date();
          //this.setState({photoURL:process.env.REACT_APP_API_URL + "/api/users/getphoto/" + this.state.currentUser.id + "/" + currentTime.getHours() + currentTime.getSeconds()});
          this.setState({photoURL:process.env.REACT_APP_API_URL + "/users/getphoto/" + this.state.currentUser.user_id + "/" + currentTime.getHours() + currentTime.getSeconds()});
          //console.log('from app... ' + this.state.currentUser);
      }
      
  }

  login = (event) => {
    event.preventDefault();

    const form = event.currentTarget

    if (form.checkValidity() === false) {
        event.stopPropagation()
    }
    else
    {    
      this.setState({validated: true});
      this.setState({ message: "Please wait...", messageClassName: 'text-info' });
      var auth = { 'email': this.state.email, 'password': this.state.password };
      fetch(process.env.REACT_APP_API_URL + "/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(auth)
      })
          .then(response => {
              if (!response.ok) {
                if(response.status == 401)
                {
                  throw "login failed";
                }
                else
                  throw response.statusText;
              }
              return response.json();
          })
          .then(result => {
              //check whether login failed
              if (result.status == 'error') {
                  this.setState({ message: result.message, messageClassName: 'text-danger' });
                  return;
              }
              else {
                  var user = JSON.stringify(result);

                  if (this.state.rememberMe) {
                      localStorage.setItem("currentUser", user);

                      var t = new Date();
                      t.setDate(t.getDate() + 30);
                      
                      localStorage.setItem("loginExpireOn", t);
                  }

                  //sessionStorage.setItem("currentUser", user);

                  document.cookie = "currentUser=" + user;

                  this.setState({ currentUser: JSON.parse(user) });

                  //document.cookie = "userLoggedIn=true";

                  this.setState({ message: "", messageClassName: 'text-success', loggedIn: true });

                  this.setState({ email: null, password: null });

                  var currentTime = new Date();
                  //this.setState({photoURL:process.env.REACT_APP_API_URL + "/api/users/getphoto/" + this.state.currentUser.id + "/" + currentTime.getHours() + currentTime.getSeconds()});
                  this.setState({photoURL:process.env.REACT_APP_API_URL + "/users/getphoto/" + this.state.currentUser.user_id + "/" + currentTime.getHours() + currentTime.getSeconds()});
                  
              }
          })
          .catch(err => {
            if(err == 'login failed')
              this.setState({ message: "Sorry, invalid email or password", messageClassName: 'text-danger' });
            else
              this.setState({ message: "Sorry, something went wrong, error: " + err, messageClassName: 'text-danger' });
          })
        }
}

logout = (e) => {
  e.preventDefault();
    fetch(process.env.REACT_APP_API_URL + "/logout", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + this.state.currentUser.access_token }
    })
        .then(response => {
            if (!response.ok) {
                throw response.statusText;
            }
            return response.json();
        })
        .then(result => {
            
        })
        .catch(err => {//console.log(err);
            this.setState({ message: "Sorry, something went wrong, error: " + err, messageClassName: 'text-danger' });
        })
  window.location = "/";
  localStorage.removeItem('currentUser');
  //sessionStorage.removeItem('currentUser');
  document.cookie = "currentUser=";
  this.setState({ currentUser: null, message: "You have signed out", messageClassName: 'text-success' });
  this.setState({ email: null, password: null });
}



componentDidMount() {
        
  if (localStorage.getItem("currentUser") !== null) {

      var now = new Date();
      var loginExpire = new Date(localStorage.getItem("loginExpireOn"));
      
      //if (now.getTime() < loginExpire.getTime()) {
          let currentUser = localStorage.getItem("currentUser");
          this.setState({ currentUser: JSON.parse(currentUser) });
          //sessionStorage.setItem("currentUser", currentUser);
          document.cookie = "currentUser=" + currentUser;
      //}
  }
  else if (getCookie("currentUser") !== "")
  {
      //let currentUser = sessionStorage.getItem("currentUser");
      let currentUser = getCookie("currentUser");
      this.setState({ currentUser: JSON.parse(currentUser) });
  }
}

  render() {
    if (!this.state.currentUser) {
      return (
        <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
              {/* <Route exact path='/forgotpwd' render={(props) => (<ForgotPassword {...props} submitEmail={this.submitEmail} myChangeHandler={this.myChangeHandler} message={this.state.message} messageClassName={this.state.messageClassName} />)}  />
              <Route exact path='/resetpwd/:token' render={(props) => (<ResetPassword {...props} />)} /> */}
              <Route path='/' render={(props) => (<Login {...props} login={this.login} validated={this.state.validated} myChangeHandler={this.myChangeHandler} message={this.state.message} messageClassName={this.state.messageClassName} />)} />
          </Switch>
          </React.Suspense>
          </HashRouter>);
    }

    // if (this.state.loggedIn) {
    //   this.setState({ loggedIn: false });
    //   return <Redirect to="/" />
    //   }

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} logout={this.logout} role={this.state.currentUser.role} currentUser={this.state.currentUser} photoURL={this.state.photoURL} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
